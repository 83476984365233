import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Traits from "../images/traits.svg"


const Rarity = () => {

  return (
    <section className="section-rarity py-32" id="rarity">
      <div class="main-container">
        <h2 className="text-6xl mb-20 md:text-7-xl text-white tshadow text-center">Rarity</h2>
        <div className="grid grid-cols-1 lg:grid-cols-5 px-4 md:px-10 gap-x-0 md:gap-x-20 gap-y-20">
          <div className="col-span-full md:col-span-3 ">
            <StaticImage placeholder="none" quality="100" src="../images/traits.svg" />
          </div>
          <div className="gap-y-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 col-span-full md:col-span-2">
            <div className="flex">
              <svg className="rarityBullet flex-none mr-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 104.32 104.32">
                <g>
                  <circle cx="52.16" cy="52.16" r="49.66" fill="#32366e" />
                  <path d="M52.16,104.32a52.16,52.16,0,1,1,52.16-52.16A52.22,52.22,0,0,1,52.16,104.32ZM52.16,5A47.16,47.16,0,1,0,99.32,52.16,47.22,47.22,0,0,0,52.16,5Z" fill="#fff" />
                </g>
                <circle cx="52.16" cy="52.16" r="35.65" fill="#e7cb20" />
              </svg>
              <p className="text-2xl md:text-3xl text-white tshadow">28 Hairs</p>
            </div>
            <div className="flex ">
              <svg className="rarityBullet flex-none mr-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 104.32 104.32">
                <g>
                  <circle cx="52.16" cy="52.16" r="49.66" fill="#32366e" />
                  <path d="M52.16,104.32a52.16,52.16,0,1,1,52.16-52.16A52.22,52.22,0,0,1,52.16,104.32ZM52.16,5A47.16,47.16,0,1,0,99.32,52.16,47.22,47.22,0,0,0,52.16,5Z" fill="#fff" />
                </g>
                <circle cx="52.16" cy="52.16" r="35.65" fill="#2551EF" />
              </svg>
              <p className="text-2xl md:text-3xl text-white tshadow">5 Color faces</p>
            </div>
            <div className="flex ">
              <svg className="rarityBullet flex-none mr-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 104.32 104.32">
                <g>
                  <circle cx="52.16" cy="52.16" r="49.66" fill="#32366e" />
                  <path d="M52.16,104.32a52.16,52.16,0,1,1,52.16-52.16A52.22,52.22,0,0,1,52.16,104.32ZM52.16,5A47.16,47.16,0,1,0,99.32,52.16,47.22,47.22,0,0,0,52.16,5Z" fill="#fff" />
                </g>
                <circle cx="52.16" cy="52.16" r="35.65" fill="#D6630D" />
              </svg>
              <p className="text-2xl md:text-3xl text-white tshadow">28 Mouths</p>
            </div>
            <div className="flex ">
              <svg className="rarityBullet flex-none mr-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 104.32 104.32">
                <g>
                  <circle cx="52.16" cy="52.16" r="49.66" fill="#32366e" />
                  <path d="M52.16,104.32a52.16,52.16,0,1,1,52.16-52.16A52.22,52.22,0,0,1,52.16,104.32ZM52.16,5A47.16,47.16,0,1,0,99.32,52.16,47.22,47.22,0,0,0,52.16,5Z" fill="#fff" />
                </g>
                <circle cx="52.16" cy="52.16" r="35.65" fill="#0DC4D6" />
              </svg>
              <p className="text-2xl md:text-3xl text-white tshadow">40 Eyes</p>
            </div>
            <div className="flex ">
              <svg className="rarityBullet flex-none mr-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 104.32 104.32">
                <g>
                  <circle cx="52.16" cy="52.16" r="49.66" fill="#32366e" />
                  <path d="M52.16,104.32a52.16,52.16,0,1,1,52.16-52.16A52.22,52.22,0,0,1,52.16,104.32ZM52.16,5A47.16,47.16,0,1,0,99.32,52.16,47.22,47.22,0,0,0,52.16,5Z" fill="#fff" />
                </g>
                <circle cx="52.16" cy="52.16" r="35.65" fill="#D80B7C" />
              </svg>
              <p className="text-2xl md:text-3xl text-white tshadow">5 Hornes</p>
            </div>
            <div className="flex">
              <svg className="rarityBullet flex-none mr-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 104.32 104.32">
                <g>
                  <circle cx="52.16" cy="52.16" r="49.66" fill="#32366e" />
                  <path d="M52.16,104.32a52.16,52.16,0,1,1,52.16-52.16A52.22,52.22,0,0,1,52.16,104.32ZM52.16,5A47.16,47.16,0,1,0,99.32,52.16,47.22,47.22,0,0,0,52.16,5Z" fill="#fff" />
                </g>
                <circle cx="52.16" cy="52.16" r="35.65" fill="#06B21E" />
              </svg>
              <p className="text-2xl md:text-3xl text-white tshadow">36 Backgrounds</p>
            </div>
          </div>

        </div>
      </div>
    </section>
  )
}

export default Rarity