import * as React from "react"

import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";

const Accordion = ({ question, answer }) => {

  const [isOpen, setIsOpen] = useState(false);

  return (
    <motion.div className="bg-white roadmapCard shadow-xl rounded-xl">
      <AnimatePresence>
        <motion.div
          key="question"
          className="cursor-pointer flex justify-between px-6 gap-x-4 items-center"
          onClick={() => setIsOpen(!isOpen)}
        >
          <motion.div className="font-bold py-8 text-xl md:text-2xl text-blue-800
          ">
            {question}
          </motion.div>
          <div>
            <button className={`lessMore ${isOpen ? "is-active" : ""}`} aria-label="Open Close"></button>
          </div>
        </motion.div>

        {isOpen && (
          <motion.div
            key="answer"
            initial={{ height: 0 }}
            animate={{
              height: 'auto',
              transition: {
                duration: 0.3,
              },
            }}
            exit={{
              height: 0,
              transition: {
                duration: 0.3,
              },
            }}
            className="text-lg overflow-hidden"
          >
            <p className="px-6 pb-8 text-blue-500">{answer}</p>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default Accordion;