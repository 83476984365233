import * as React from "react"
import Accordion from "../components/Accordion"

const Faq = () => {
  const Questions = [
    {
      q: 'What is the total supply?',
      a: <p>
        4096 high quality, hand-drawn KAMINARI NFTs *
Original art makes every KAMINARI one of a kind.
      </p>
    },
    {
      q: `When is the release date?`,
      a: <p>
        Stay tuned, the date will be announced shortly.
      </p>
    },
    {
      q: `How many can I purchase`,
      a: <p>
        During Public Sale you will be able to MAX mint 4 NFTs per wallet et per transaction 
      </p>
    },
    {
      q: `Is there different types of rarity?`,
      a: <p>
        All KAMINARI and their various parts are drawn by hand and algorithmically generated using custom code
With these combined, it gives a total mathematical combination of millions of unique KAMINARI - but with only 4,096 being minted, some are more unique and rare than others.
      </p>
    },
    {
      q: `What is the utility of my KAMINARI NFT ?`,
      a: <p>
        The KAMINARI can be used as item to trade on the secondary market or item to keep for profit as we follow the multiple-phases roadmap including some exciting partnership related to NFT
      </p>
    },
    {
      q: `What is the mint price?`,
      a: <p>
        The mint price is 0.06 ETH.
Join the discord channel to receive the exclusive link to be able to mint. <br></br>The link will be disclosed on discord to avoid bots and available for a limited time only.<br></br>
After the mint the collection will be made available and verified on a  selected marketplace announced on the discord so keep an eye out and be sure to join our community. 
      </p>
    }
  ]
  return (
    <section className="pt-32" id="faq">
      <div class="main-container">
        <h2 className="text-6xl md:text-7-xl mb-20 pb-4 text-center text-white tshadow font-head uppercase">faq</h2>
        <div class="grid grid-cols-1 gap-y-8">
          {Questions.map((question) =>
            <Accordion question={question.q} answer={question.a} />
          )}
        </div>
      </div>
    </section>
  )
}

export default Faq