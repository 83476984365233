import React, { useEffect } from "react"
import Countdown, { zeroPad } from 'react-countdown';
import Discord from '../images/Discord-Logo-White.svg'

const MintCountdown = () => {

  const date1 = new Date('december 8, 2021');
  const Completionist = () => <span>You are good to go!</span>;

  // Renderer callback with condition
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <Completionist />;
    } else {
      // Render a countdown
      return (
        <div className="flex justify-center">
          <div className="grid grid-flow-col gap-2 md:gap-5 text-center auto-cols-max">
          <div className="flex flex-col bg-gradient-to-b from-blue-500  to-blue-600 py-2 px-3 rounded">
          <span className="text-white text-3xl md:text-4xl">
                <span>{zeroPad(days)}</span>
              </span>
              <span className="text-white font-bold">days</span>
            </div>
            <div className="flex flex-col bg-gradient-to-b from-blue-500  to-blue-600 py-2 px-3 rounded">
            <span className="text-white text-3xl md:text-4xl">
                <span>{zeroPad(hours)}</span>
              </span>
              <span className="text-white font-bold">hours</span>
            </div>
            <div className="flex flex-col bg-gradient-to-b from-blue-500  to-blue-600 py-2 px-3 rounded">
            <span className="text-white text-3xl md:text-4xl">
                <span>{zeroPad(minutes)}</span>
              </span>
              <span className="text-white font-bold">min</span>
            </div>
            <div className="flex flex-col bg-gradient-to-b from-blue-500  to-blue-600 py-2 px-3 rounded">
              <span className="text-white text-3xl md:text-4xl">
                <span>{zeroPad(seconds)}</span>
              </span>
              <span className="text-white font-bold">sec</span>
            </div>
          </div>
        </div>
      )
    }
  };

  return (
    <section>
      <div className="relative text-center">
        <div className="z-20 relative">
          <p className="text-white font-bold text-3xl md:text-4xl lg:text-5xl tshadow uppercase mb-10">Mint coming soon</p>
          {/* <p className="mb-10">
            <Countdown date={date1} renderer={renderer} zeroPadTime={2} />
          </p> */}
          <a href="https://discord.com/invite/MSyfDN6NMc" target="_blank" rel="noreferrer" className="text-lg md:text-xl lg:text-2xl shadow-2xl bg-gradient-to-b from-pink-500  to-pink-600 hover:from-indigo-500 hover:to-indigo-600 transition duration-500 text-white font-bold py-4 px-6 rounded-full inline-flex items-center">
            <img src={Discord} className="w-6 h-6 mr-2" alt="" />
            <span>Join us on discord</span>
          </a>
        </div>
      </div>
    </section>

  )
}

export default MintCountdown
