import * as React from "react"

const Roadmap = () => {

  const Phases = [
    {
      title: 'Phase 1',
      subtitle: 'Join the Kaminari Community',
      items: [
          `Airdrop of 1 Kaminari to 10 random Kaminari owner wallets.`
        ,
        `
          Limited and exclusive merchandising (hoodies, tees) only for owners 
        `,
        `Level Up your Kaminari `,
        `Collect Wearables + 3D Avatars and receive some unique rewards` 
      ]
    },
    {
      title: 'Phase 2',
      subtitle: <p>Prepare for the ultimate #Play2Earn experience!</p>,
      items: [
          `Hangout with Friends and Experience Virtual Events with Live Performances with Metaverse Expansion`,
          `Complete Play-2-Earn Adventures`,
          `Win Exclusive Prizes based on Kaminari culture and lifestyle!`,
          <>
          <span className="text-yellow-300 ">SPOIL ALERT :&nbsp;</span>
          &nbsp;We will be picking one wallet that mints 3 NFTs and booking them a 2 week ALL EXPENSES paid trip to Tokyo, Japan for 2 persons.
          </>,
          `Airdrop of 1 Kaminari to 10 random Kaminari owner wallets.`,
          `3D files will be made available to Kaminari owners`,
          `Profile pictures headshots of your Kaminari can now be claim and downloaded from our website.`,
          `Additional collections for honararies and add-on assets for our Kaminari are on the road.`
      ]
    },
  ]
  return (
    <section className="text-white pt-40 md:pt-72 pb-0 xl:pb-10" id="roadmap">
      <div className="main-container mx-auto">
        <h2 className="text-5xl md:text-6xl mb-10 pb-4 tshadow text-center">Roadmap</h2>
        <div className="grid gap-y-20 grid-cols-1">
          {Phases.map((phase) =>
            <div>
              <div key={phase.title}>
                <h2 className="text-3xl md:text-4xl font-extrabold text-blue-900 phaseTitle text-center">{phase.title}</h2>
                <h3 className="text-2xl font-bold mb-10 text-white tshadow text-center">{phase.subtitle}</h3>
                <div className="relative space-y-6 text-white tshadow text-left md:text-center">
                  {phase.items.map((item) =>
                    <p className="font-medium ml-8 leading-6 text-white">
                     <span className="text-indigo-300">></span> {item}
                    </p>
                  )}
                </div>
                <p className="text-5xl tracking-wider tshadow text-center">...</p>

              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export default Roadmap