import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
const Team = () => {
  return (
    <section id="team">
      <div class="main-container py-32">
        <h2 className="text-6xl md:text-7-xl mb-10 pb-4 text-center text-white font-head tshadow">The team</h2>
        <div class="grid grid-cols-2 lg:grid-cols-4 gap-4 md:gap-20">
          <div className="text-center">
            <div className="px-3 mb-2">
              <StaticImage placeholder="none" src="../images/team/KApal.png" />
            </div>
            <h3 className="tshadow text-3xl mb-2 font-bold text-white">KApal</h3>
            <p className="text-xl text-cbluedark">Creator</p>
          </div>
          <div className="text-center">
            <div className="pl-3 pr-3 mb-2">
              <StaticImage placeholder="none" src="../images/team/KABG.png" />
            </div>
            <h3 className="tshadow text-3xl mb-2 font-bold text-white">KAB G</h3>
            <p className="text-xl text-cbluedark">Belzebuth</p>
          </div>
          <div className="text-center">
            <div className="pl-3 pr-3 mb-2">
              <StaticImage placeholder="none" src="../images/team/KAtin.png" />
            </div>
            <h3 className="tshadow text-3xl mb-2 font-bold text-white">KAtin</h3>
            <p className="text-xl text-cbluedark">Web</p>
          </div>
          <div className="text-center">
            <div className="pl-3 pr-3 mb-2">
              <StaticImage placeholder="none" src="../images/team/KAJP.png" />
            </div>
            <h3 className="tshadow mb-2 text-3xl font-bold text-white ">KAJP</h3>
            <p className="text-xl text-cbluedark">Codes</p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Team