import * as React from "react"
import { useState, useEffect } from "react";
import { Link, animateScroll as scroll } from "react-scroll";
import { motion } from "framer-motion"
const Nav = () => {
  const variantsNav = {
    open: {
      y: "0",
      transition: {
        delayChildren: 0.5,
        staggerChildren: 0.8,
        duration: 0.7,
        ease: [0.87, 0, 0.13, 1],
      },
    },
    closed: {
      transition: {
        delay: 0.3,
        staggerChildren: 0.07,
        duration: 0.7,
        ease: [0.87, 0, 0.13, 1],
      },
      y: "-100%",
    },
  }
  const variantsLi = {
    open: {
      y: 0,
      opacity: 1,
      transition: {
        y: { stiffness: 1000, velocity: -100 }
      }
    },
    closed: {
      y: 50,
      opacity: 0,
      transition: {
        y: { stiffness: 1000 }
      }
    }
  }
  const variantsUl = {
    open: {
      opacity:1,
      transition: { staggerChildren: 0.05 }
    },
    closed: {
      opacity:0,
      transition: { staggerChildren: 0.05, staggerDirection: -1 }
    }
  }
  const [isOpen, setIsOpen] = useState(false)
  const Links = [
    {
      title: 'Roadmap',
      anchor: 'roadmap'
    },
    {
      title: 'Rarity',
      anchor: 'rarity'
    },
    {
      title: 'Faq',
      anchor: 'faq'
    },
    {
      title: 'Team',
      anchor: 'team'
    },
  ]
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "auto"
    }
  }, [isOpen])
  return (
    <header>
      <button className="cursor-pointer flex items-center bg-gradient-to-b from-pink-500 to-pink-600 hover:bg-indigo-600 shadow fixed py-2 px-2 rounded-xl right-6 top-6 z-100" onClick={() => setIsOpen(!isOpen)}>
        <div className={`rounded-full menu-icon focus:outline-none ${isOpen ? "is-active" : ""}`}></div>
      </button>
      <motion.nav
        initial={false}
        animate={isOpen ? "open" : "closed"}
        variants={variantsNav}
        className="fixed z-75 inset-0 bg-indigo-600 text-sm overflow-hidden overflow-y-auto"
      >
        <motion.ul variants={variantsUl} className="flex flex-col gap-y-6 menuItemsContainer text-center">
          {Links.map((link) =>
            <motion.li variants={variantsLi}>
              <Link
                activeClass="text-yellow-300"
                onClick={() => setIsOpen(!isOpen)}
                className="tshadow cursor-pointer text-4xl md:text-5xl inline-block text-white hover:text-yellow-300 transition duration-300"
                to={link.anchor}
                spy={true}
                hashSpy={true}
                smooth={true}
                duration={1000}
                delay={300}

              >{link.title}</Link>

            </motion.li>
          )}
        </motion.ul>
      </motion.nav>
    </header>
  )
}


export default Nav