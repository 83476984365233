import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import MintVideo from '../static/Kaminari_Mint_Machin_CROP.mp4'
import MintCountdown from "../home/MintCountdown"

const Banner = () => {

  return (
    <div class="header overflow-hidden pt-16">
      <div class="main-container relative">
        <div class="relative main-container w-full pb-4 md:pb-10">
          <StaticImage placeholder="none" className="bigCloud" src="../images/head/backcloud.png" />
          <div className="absolute z-20 top-0 headCloud">
            <StaticImage placeholder="none" src="../images/head/headcloud.png" />
          </div>
          <div className="absolute z-30 top-0">
            <StaticImage placeholder="none" src="../images/head/frontcloud.png" />
          </div>
          <div className="absolute z-40 top-0">
            <StaticImage placeholder="none" src="../images/head/kaminarilogo.png" />
          </div>
          <div className="absolute z-50 top-0 storm">
            <StaticImage placeholder="none" src="../images/head/kaminarilogo2.png" />
          </div>
        </div>
        <div className="absolute z-40 bottom-0 mx-auto">
          <div className="grid grid-cols-5">
            <StaticImage quality="100" className="w-full" src="../images/kaminari/Kaminari_119.png" objectFit="cover" placeholder="none" alt="A solsquares" />
            <StaticImage quality="100" className="w-full" src="../images/kaminari/Kaminari_97.png" objectFit="cover" placeholder="none" alt="A solsquares" />
            <StaticImage quality="100" className="w-full" src="../images/kaminari/Kaminari_92.png" objectFit="cover" placeholder="none" alt="A solsquares" />
            <StaticImage quality="100" className="w-full" src="../images/kaminari/Kaminari_31.png" objectFit="cover" placeholder="none" alt="A solsquares" />
            <StaticImage quality="100" className="w-full" src="../images/kaminari/Kaminari_94.png" objectFit="cover" placeholder="none" alt="A solsquares" />
          </div>
        </div>
      </div>

      <section className="pt-0 md:pt-10">
        <div class="main-container">
          <div class="grid grid-cols-1 md:grid-cols-2">
            <video className="object-contain w-full h-full" loop="true" autoplay="autoplay" muted="muted" playsInline="playsInline">
              <source src={MintVideo} type="video/mp4" />
            </video>
            <div className="flex items-center justify-center">
              <MintCountdown />
            </div>
          </div>
        </div>
      </section>

    </div>

  )
}

export default Banner