import React from "react"
import Layout from "../components/layout"

import Nav from "../components/Nav"
import Faq from '../home/Faq'
import Team from "../home/Team"
import Banner from "../home/Banner"
import Roadmap from "../home/Roadmap"
import Rarity from "../home/Rarity"
import Footer from "../home/Footer"
import Seo from "../components/Seo"
import ImageSeo from "../static/social.png"

const IndexPage = () => {

  return (
    <Layout>
      <Seo
        image={ImageSeo}
      />
      <Nav />
      <Banner />
      <Roadmap />
      <Rarity />
      <div className="kaminariSlide1"></div>
      <div className="kaminariSlide2"></div>
      <div className="kaminariSlide3"></div>
      <Faq />
      <Team />
      <Footer />
    </Layout>
  )
}

export default IndexPage